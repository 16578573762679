
import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import Loadable from 'react-loadable';
import Loading from './loading';
import ScrollToTop from './ScrollToTop';

// import { HashRouter as Router, Route, Switch } from "react-router-dom";

// Home screen
const App = Loadable({ loader: (() =>  import(/* webpackChunkName: "home" */'./App')), loading: Loading });
const About = Loadable({ loader: (() =>  import(/* webpackChunkName: "home" */'./pages/about/About')), loading: Loading });

// Login screen
const SignInModel = Loadable({ loader: (() =>  import(/* webpackChunkName: "login" */'../src/Components/signInModel/SignInModel')), loading: Loading })
const ConfirmForgotPassword = Loadable({ loader: (() =>  import(/* webpackChunkName: "login" */'../src/pages/confirmForgotPassword/ConfirmForgotPassword')), loading: Loading })
// const PageList = Loadable({ loader: (() =>  import('./pages/pagesList/PagesList')), loading: Loading })

// Registration flow screen
const RegisterModel = Loadable({ loader: (() =>  import(/* webpackChunkName: "registration" */'../src/Components/registerModel/RegisterModel')), loading: Loading })
const BasicDetails = Loadable({ loader: (() =>  import(/* webpackChunkName: "registration" */'./pages/basicDetails/BasicDetails')), loading: Loading })
const RegAdditionalDetails = Loadable({ loader: (() =>  import(/* webpackChunkName: "registration" */'./pages/regAdditionalDetails/RegAdditionalDetails')), loading: Loading })
const Confirmation = Loadable({ loader: (() =>  import(/* webpackChunkName: "registration" */'./pages/register/Confirmation')), loading: Loading })

// Browser - Categories and Details page
const SearchResults = Loadable({ loader: (() =>  import(/* webpackChunkName: "browse" */'./pages/searchResults/SearchResults')), loading: Loading })
const ProductDetail_1_AdditionalDetails = Loadable({ loader: (() =>  import(/* webpackChunkName: "browse" */'../src/pages/productDetail_1_AdditionalDetails/ProductDetail_1_AdditionalDetails')), loading: Loading })
const MyCatalogLanding = Loadable({ loader: (() =>  import(/* webpackChunkName: "browse" */'../src/pages/myCatalogLanding/MyCatalogLanding')), loading: Loading })
const MyCatalogProductDetails = Loadable({ loader: (() =>  import(/* webpackChunkName: "browse" */'../src/pages/myCatalogProductDetails/MyCatalogProductDetails')), loading: Loading })
const CategoriesLanding = Loadable({ loader: (() =>  import(/* webpackChunkName: "browse" */'../src/pages/categoriesLanding/CategoriesLanding')), loading: Loading })
const RentalRequest = Loadable({ loader: (() =>  import(/* webpackChunkName: "browse" */'../src/pages/rentalRequest/RentalRequest')), loading: Loading })
const CayalRentalRequestFormLanding = Loadable({ loader: (() =>  import(/* webpackChunkName: "browse" */'../src/pages/Cayal-RentalRequest-2-FormLanding/Cayal-RentalRequest-2-FormLanding')), loading: Loading })

// Checkout flow
const ProductDetailsReservation = Loadable({ loader: (() =>  import(/* webpackChunkName: "checkout" */'./pages/productDetail_2_Reservation/ProductDetail_2_Reservation')), loading: Loading });
const PaymentConfirmation = Loadable({ loader: (() =>  import(/* webpackChunkName: "checkout" */'../src/pages/paymentConfimation/PaymentConfirmation')), loading: Loading })

// Transactions details
const MyTransactionsProductsRentedInActive = Loadable({ loader: (() =>  import(/* webpackChunkName: "transactions" */'../src/pages/myTransactionsProductsRented_in_Active/MyTransactionsProductsRentedInActive')), loading: Loading })
const MyTransactionsProductsReserved = Loadable({ loader: (() =>  import(/* webpackChunkName: "transactions" */'../src/pages/myTransactionProductsReserved/MyTransactionProductsReserved')), loading: Loading })
const MyTransactionsProductsRentedOutActive = Loadable({ loader: (() =>  import(/* webpackChunkName: "transactions" */'../src/pages/myTransactionsProductsRented_out_Active/MyTransactionsProductsRentedOutActive')), loading: Loading })
const ReservationConfirmation = Loadable({ loader: (() =>  import(/* webpackChunkName: "transactions" */'../src/pages/reservationConfirmation/ReservationConfirmation')), loading: Loading })
const CancelReservation = Loadable({ loader: (() =>  import(/* webpackChunkName: "transactions" */'../src/pages/cancelReservation/cancelReservation')), loading: Loading })
const CancelReservationScreen = Loadable({ loader: (() =>  import(/* webpackChunkName: "transactions" */'../src/pages/cancelReservation/cancelReserveScreen')), loading: Loading })

// User profile
const MyAccount = Loadable({ loader: (() =>  import(/* webpackChunkName: "profile" */'./pages/myAccount/MyAccount')), loading: Loading })
const ProfilePrimaryDetail = Loadable({ loader: (() =>  import(/* webpackChunkName: "profile" */'./pages/profilePrimaryDetail/ProfilePrimaryDetail')), loading: Loading })
const ProfileAdditionalDetails = Loadable({ loader: (() =>  import(/* webpackChunkName: "profile" */'./pages/profileAdditionalDetails/ProfileAdditionalDetails')), loading: Loading })
const CatalogRentalHistory = Loadable({ loader: (() =>  import(/* webpackChunkName: "profile" */'./pages/catalogRentalHistory/CatalogRentalHistory')), loading: Loading })

// Policies screen
const FAQs = Loadable({ loader: (() =>  import(/* webpackChunkName: "policies" */'./pages/faqs/FAQs')), loading: Loading })
const ContactUs = Loadable({ loader: (() =>  import(/* webpackChunkName: "policies" */'./pages/contactUs/ContactUs')), loading: Loading })
const HowItsWork = Loadable({ loader: (() =>  import(/* webpackChunkName: "policies" */'../src/pages/howItsWork/HowItsWork.js')), loading: Loading })
const PrivacyPolicy = Loadable({ loader: (() =>  import(/* webpackChunkName: "policies" */'../src/pages/privacyPolicy/PrivacyPolicy.js')), loading: Loading })
const TermAndServices = Loadable({ loader: (() =>  import(/* webpackChunkName: "policies" */'../src/pages/termAndServices/TermAndServices.js')), loading: Loading })

// Manage products screen
const CreateListing_1b = Loadable({ loader: (() =>  import(/* webpackChunkName: "manageproducts" */'./pages/createListing1b/CreateListing_1b')), loading: Loading })
const EditProduct = Loadable({ loader: (() =>  import(/* webpackChunkName: "manageproducts" */'../src/pages/editProduct/EditProduct')), loading: Loading })
const DeleteProductStatus = Loadable({ loader: (() =>  import(/* webpackChunkName: "manageproducts" */'../src/pages/deleteProductStatus/DeleteProductStatus')), loading: Loading })

// Admin screen
const Admin_6_PendingRentalRequests = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'./pages/admin_6PendingRentalRequests/Admin_6_PendingRentalRequests')), loading: Loading })
const AdminDashboard = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'./pages/adminDashboard/AdminDashboard')), loading: Loading })
const AdminProductDetail = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'./pages/admin10ProductDetail_Avaliable/AdminProductDetail')), loading: Loading })
const AdminProductReserveDetail = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'./pages/admin10ProductDetail_Reserved/AdminProductReserveDetail')), loading: Loading })
const AdminPage = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'./pages/admin_page/AdminPage')), loading: Loading })
const NewUserAdditionalDetail = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'./pages/newUserAdditionalDetail/NewUserAdditionalDetail')), loading: Loading })
const Admin_PendingRentalRequests = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/admin_8PendingRentalRequests/Admin_PendingRentalRequests')), loading: Loading })
const PendingNewUserVerification = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/admin_4/PendingNewUserVerification')), loading: Loading })
const PendingNewProductReviews = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/admin_2/PendingNewProductReviews')), loading: Loading })
const AdminApproveNewProductsConfirmation = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/adminApproveNewProductsConfirmation/AdminApproveNewProductsConfirmation')), loading: Loading })
const AdminApproveRentRequestConfirmation = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/adminApproveRentalRequestConfirmation/AdminApproveRentalRequestConfirmation')), loading: Loading })
const NewProductConfirmation = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/newProductConfirmation/NewProductConfirmation')), loading: Loading })
const UserPrimaryReview = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/userPrimaryReview/UserPrimaryReview')), loading: Loading })
const UserAdditionalReview = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/userAdditionalReview/UserAdditionalReview')), loading: Loading })
const UserVerificationConfirmation = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/userVerificationConfirmation/UserVerificationConfirmation')), loading: Loading })
const ProductsList = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'./pages/admin_5/ProductsList')), loading: Loading })
const ApproveNewProduct = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'./pages/admin_7/ApproveNewProduct')), loading: Loading })
const UserList = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/admin3b/UserList')), loading: Loading })
const RequestProductList = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/requestProductList/RequestProductList.js')), loading: Loading })
const RequestDetails = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/requestDetails/RequestDetails')), loading: Loading })
const FailedReservations = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/failedReservations/FailedReservations.js')), loading: Loading })
const ProductRentOut = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/productRentOut/ProductRentOut')), loading: Loading })
const ProductReturned = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/productReturned/ProductReturned')), loading: Loading })

const ExploreOurCatalogue = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/exploreOurCatalogue/ExploreOurCatalogue')), loading: Loading })
const NewsLetter = Loadable({ loader: (() =>  import(/* webpackChunkName: "admin" */'../src/pages/newsLetter/NewsLetter')), loading: Loading })

function PrivateAdminRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('username') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              // state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}
export default class CayalRouter extends Component {
  render() {
    const BrowserRouter = require('react-router-dom').BrowserRouter
    return (
        <div className="CayalRouter">
            <BrowserRouter>
              <ScrollToTop>
                <Switch>
                  {/* admin pages routing */}
                  <Route exact path="/admin-page" component={AdminPage} />
                  <PrivateAdminRoute exact path="/pending-new-product-reviews" component={PendingNewProductReviews} />
                  <PrivateAdminRoute exact path="/admin-dashboard" component={AdminDashboard} />
                  <PrivateAdminRoute exact path="/pending-new-user-verification" component={PendingNewUserVerification} />
                  <PrivateAdminRoute exact path="/admin-6-pending-rental-requests/" component={Admin_6_PendingRentalRequests} />
                  <PrivateAdminRoute exact path="/request-product-list" component={RequestProductList} />
                  <PrivateAdminRoute exact path="/failed-reservations" component={FailedReservations} />
                  <PrivateAdminRoute exact path="/products-list" component={ProductsList} />
                  <PrivateAdminRoute exact path="/user-list" component={UserList} />
                  <PrivateAdminRoute exact path="/product-rent-out" component={ProductRentOut} />
                  <PrivateAdminRoute exact path="/product-returned-out" component={ProductReturned} />
                  <PrivateAdminRoute exact path="/user-primary-review/:user_id?" component={UserPrimaryReview} />
                  <PrivateAdminRoute exact path="/user-additional-review/:user_id?" component={UserAdditionalReview} />
                  <PrivateAdminRoute exact path="/request-detail" component={RequestDetails} />
                  <PrivateAdminRoute exact path="/approve-new-product/:product_id?" component={ApproveNewProduct} />
                  <PrivateAdminRoute exact path="/admin-pending-rental-requests/:product_id/:rental_type?/" component={Admin_PendingRentalRequests} />

                  <Route exact path="/" component={App} />
                  <Route path="/register" component={App} />
                  <Route exact path="/about" component={About} />
                  <Route exact path="/my-account" component={MyAccount} />
                  <Route exact path="/profile-primary-detail/:user_id?" component={ProfilePrimaryDetail} />
                  <Route exact path="/profile-additional-detail" component={ProfileAdditionalDetails} />
                  <Route exact path="/catalog-rental-history/:product_id?" component={CatalogRentalHistory} />
                  <Route exact path="/conformation" component={Confirmation} />
                  <Route exact path="/faq" component={FAQs} />
                  <Route exact path="/basic-detail/:product_id?/:cat_id?/:product_name?" component={BasicDetails} />
                  <Route exact path="/reg-additional-detail/:product_id?/:cat_id?/:product_name?" component={RegAdditionalDetails} />
                  <Route exact path="/contactus/:text?" component={ContactUs} />
                  <Route exact path="/search-result/:cat_id/:subcat_id/:query/" component={SearchResults} />
                  <Route exact path="/create-listing" component={CreateListing_1b} />
                  <Route exact path="/admin-product-detail/:product_id/" component={AdminProductDetail} />
                  <Route exact path="/admin-product-detail-reserve/:product_id/" component={AdminProductReserveDetail} />
                  <Route exact path="/new-user-additional-detail" component={NewUserAdditionalDetail} />
                  <Route exact path="/product-details/:product_id/:cat_id?/:subcate_id?/:product_name?/" component={ProductDetail_1_AdditionalDetails} />
                  <Route exact path="/register-model" component={RegisterModel} />
                  <Route exact path="/sign-in-model" component={SignInModel} />
                  <Route exact path="/categories-landing" component={CategoriesLanding} />
                  <Route exact path="/product-detail-reservation/:product_id/:cat_id/:sub_cat/:product_name/:reserve_id?/:reserve_type?/:end_date?/:borrower?" component={ProductDetailsReservation} />
                  <Route exact path="/my-catalog-landing/:email_id?" component={MyCatalogLanding} />
                  <Route exact path="/my-catalog-product-details" component={MyCatalogProductDetails} />
                  <Route exact path="/my-transactions-products-rented-in-active/:reservation_slug?" component={MyTransactionsProductsRentedInActive} />
                  <Route exact path="/my-transaction-products-reserved" component={MyTransactionsProductsReserved} />
                  <Route exact path="/my-transactions-products-rented-out-active/:reservation_slug?" component={MyTransactionsProductsRentedOutActive} />
                  <Route exact path="/admin-approve-new-products-confirmation/:product_name?" component={AdminApproveNewProductsConfirmation} />
                  <Route exact path="/reservation-confirmation" component={ReservationConfirmation} />

                  <Route exact path="/my-transactions-rent-request-confirmation" component={AdminApproveRentRequestConfirmation} />
                  <Route exact path="/new-product-confirmation/:product_name?/:edit_product?" component={NewProductConfirmation} />
                  <Route exact path="/payment-confirmation/:product_name?/:days?/" component={PaymentConfirmation} />
                  <Route exact path="/user-verification-confirmation" component={UserVerificationConfirmation} />
                  <Route exact path="/edit-product/:product_id?/:product_name?" component={EditProduct} />
                  <Route exact path="/confirm-forgot-password/:email_id?" component={ConfirmForgotPassword} />
                  <Route exact path="/cancel-reservation/:product_name?/:req_name?" component={CancelReservation} />
                  <Route exact path="/cancel-reservation-screen/:product_name?/:start_date?/:end_date?" component={CancelReservationScreen} />
                  <Route exact path="/delete-product-status/:product_name?/:owner?/:reason?/:email_id?" component={DeleteProductStatus} />
                  <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                  <Route exact path="/terms-services" component={TermAndServices} />
                  <Route exact path="/rental-request/:slug?" component={RentalRequest} />
                  <Route exact path="/rental-req-submit-form/:email_id?" component={CayalRentalRequestFormLanding} />
                  <Route exact path="/how-it-work" component={HowItsWork} />

                  <Route exact path="/explore-our-catalogue" component={ExploreOurCatalogue} />
                  <Route exact path="/news-letter" component={NewsLetter} />
                </Switch>
              </ScrollToTop>
            </BrowserRouter>
        </div>
      );
    }
}


