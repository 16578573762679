import { STORE_AGE } from '../Actions/ageAction';


export default function ageReducer(state = [], {type, payload}){
    switch(type){
        case STORE_AGE:
            return payload;
        default:
            return state;
    }
}
