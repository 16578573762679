import { STORE_CATEGORIES } from '../Actions/categoriesActions';


export default function categoriesReducer(state = [], {type, payload}){
    switch(type){
        case STORE_CATEGORIES:
            return payload;
        default:
            return state;
    }
}