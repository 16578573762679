import { STORE_LOCATION_ORDER } from '../Actions/locationOrderActions';


export default function locationOrderReducer(state = [], {type, payload}){
    switch(type){
        case STORE_LOCATION_ORDER:
            return payload;
        default:
            return state;
    }
}