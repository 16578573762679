import { STORE_REQUEST_JSON } from '../Actions/requestJsonActions.js';


export default function requestJsonReducer(state = [], {type, payload}){
    switch(type){
        case STORE_REQUEST_JSON:
            return payload;
        default:
            return state;
    }
}