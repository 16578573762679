import { UPDATE_AUTH, TEST_CITY_ACTION } from '../Actions/authActions';

export default function authReducer(state = {username: null, is_admin: false ,token: {}}, {type, payload}){
    switch(type){
        case UPDATE_AUTH:
            return {...state, username: payload.username,
                                is_admin: payload.is_admin,
                                token: payload.token};
        case TEST_CITY_ACTION:
            return {...state, city: payload.city};
        default:
            return state;
    }
}
