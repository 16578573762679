import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import CayalRouter from './CayalRouter'

import { Provider } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import authReducer from './Redux/Reducers/authReducer';
import uiStateReducer from './Redux/Reducers/uiStateReducer';
import categoriesReducer from './Redux/Reducers/categoriesReducer';
import locationOrderReducer from './Redux/Reducers/locationOrderReducer';
import ageReducer from './Redux/Reducers/ageReducer';
import requestJsonReducer from './Redux/Reducers/requestJsonReducer';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import * as serviceWorker from './serviceWorker';

require('react-datepicker/dist/react-datepicker.css');

const THEME = createMuiTheme({
  typography: {
   "fontFamily": 'Raleway',
   "fontSize": 14,
   "fontWeightLight": 300,
   "fontWeightRegular": 400,
   "fontWeightMedium": 500
  }
});
const allReducers = combineReducers({
  auth: authReducer,
  uiState: uiStateReducer,
  categories: categoriesReducer,
  location_lists: locationOrderReducer,
  request_details: requestJsonReducer,
  age: ageReducer
})

const store = createStore(allReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());


// store.dispatch(testAction);

ReactDOM.render((
  <ThemeProvider theme={THEME}>
    <Provider store={store}>
      <CayalRouter />
    </Provider>
  </ThemeProvider>
  ), document.getElementById('root'))
// ReactDOM.render(<App />, document.getElementById('root'));

//serviceWorker.register();
//registerServiceWorker();
serviceWorker.unregister();