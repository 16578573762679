export const UPDATE_AUTH = "authReducer:UPDATE_AUTH";
export const TEST_CITY_ACTION = "authReducer:TESTCity";

export function updateAuth(data){
    return {
        type: UPDATE_AUTH,
        payload: data
    }
}


