import { CLOSE_LOGIN_MODAL, OPEN_LOGIN_MODAL } from '../Actions/uiStateActions'

import { CLOSE_REGISTER_MODAL, OPEN_REGISTER_MODAL } from '../Actions/uiStateActions'

import { CLOSE_DRAWER , OPEN_DRAWER } from '../Actions/uiStateActions'

const initialState = {
    showPopupSignIn: false,
    showPopupRegister: false,
    openDrawer: false,
}

export default function uiStateReducer(state = initialState, {type, payload}){
    switch(type){
        case OPEN_LOGIN_MODAL:
            return {...state, showPopupSignIn: true, showPopupRegister: false, openDrawer: false};
        case CLOSE_LOGIN_MODAL:
            return {...state, showPopupSignIn: false};

        case OPEN_REGISTER_MODAL:
            return {...state, showPopupRegister: true, showPopupSignIn: false, openDrawer: false};
        case CLOSE_REGISTER_MODAL:
            return {...state, showPopupRegister: false};

        case OPEN_DRAWER:
            return {...state, showPopupRegister: false, showPopupSignIn: false, openDrawer: true};
        case CLOSE_DRAWER:
            return {...state, openDrawer: false};
        default:
            return state;
    }
}

