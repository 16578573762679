export const CLOSE_LOGIN_MODAL = "uiStateReducer:CLOSE_LOGIN_MODAL";
export const OPEN_LOGIN_MODAL = "uiStateReducer:OPEN_LOGIN_MODAL";

export const CLOSE_REGISTER_MODAL = "uiStateReducer:CLOSE_REGISTER_MODAL";
export const OPEN_REGISTER_MODAL = "uiStateReducer:OPEN_REGISTER_MODAL";

export const CLOSE_DRAWER = "uiStateReducer:CLOSE_DRAWER";
export const OPEN_DRAWER = "uiStateReducer:OPEN_DRAWER";

export function closeLoginModal(data){
    return {
        type: CLOSE_LOGIN_MODAL,
        payload: data
    }
}

export function openLoginModal(data){
    return {
        type: OPEN_LOGIN_MODAL,
        payload: data
    }
}

/************************Register Modal**************************** */

export function closeRegisterModal(data){
    return {
        type: CLOSE_REGISTER_MODAL,
        payload: data
    }
}

export function openRegisterModal(data){
    return {
        type: OPEN_REGISTER_MODAL,
        payload: data
    }
}

/************************DRAWER**************************** */

export function closeDrawer(data){
    return {
        type: CLOSE_DRAWER,
        payload: data
    }
}

export function openDrawer(data){
    return {
        type: OPEN_DRAWER,
        payload: data
    }
}